<template>
  <footer class="d-flex justify-content-between align-items-center">
    <div>
      <a href="#" class="mr-1">Resume</a>
      <a class="mr-1" href="https://github.com/Rosie-bb" target="_blank">Github</a>
      <a href="http://validator.kldp.org/check?uri=referer"
          onclick="this.href=this.href.replace(/referer$/,encodeURIComponent(document.URL))"><img
          src="//validator.kldp.org/w3cimgs/validate/html5-blue.png" alt="Valid HTML 5" height="15" width="80"></a>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <span class="mr-1">© 2023 hyemin</span>
      <img src="@/assets/image/smile.svg" alt="smile icon">
    </div>
  </footer>
</template>