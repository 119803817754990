<template>
  <Nav/>
  <Content/>
  <Footer/>
</template>

<script>
import Nav from '@/components/MainNav.vue'
import Content from '@/components/MainContent.vue'
import Footer from '@/components/MainFooter.vue'

export default {
  name: 'App',
  components: {
    Nav,
    Content,
    Footer
  }
}
</script>

<style>
  /* Web font */
  @import url('//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css');

  /* Color */
  :root {
    --color-black-100: #000000;
    --color-white-100: #ffffff;
    --color-yellow-100: #FFD100;
    --color-red-100: #FF4031;
    --color-blue-100: #2F86DD;
    --color-blue-50: #6bb5ff;
    --color-orange-100: #F19B0B;
    --color-purple-100: #CB8BDA;
    --color-pink-100: #EB5758;
    --color-gray-100: #969899;
    --color-gray-50: #D8D8D8;
    --color-gray-30: #E9E9E9;
    --color-gray-10: #F5F5F5;
    --color-blue-darke-100: #1C1F21;
  }

  * {
    font-family: 'Spoqa Han Sans', 'Spoqa Han Sans JP', 'Sans-serif';
    font-weight: normal;
    font-size: 14px;
    box-sizing: border-box;
    word-break: keep-all;
  }

  nav,
  header,
  footer,
  main {
    padding: 50px 60px;
  }

  nav a {
    text-decoration: none;
    font-weight: bold;
  }

  a:link, a:visited, a:active {
    color: var(--color-black-100);
  }
  a:hover {
    transition: color .2s;
    color: var(--color-gray-100);
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  /* Font size */
  .header-1 {
    font-size: 34px;
  }

  .header-2 {
    font-size: 20px;
  }

  .fs-0 {
    font-size: 50px;
  }

  .fs-1 {
    font-size: 40px;
  }

  .fs-2 {
    font-size: 32px;
  }

  .fs-3 {
    font-size: 28px;
  }

  .fs-4 {
    font-size: 24px;
  }

  .fs-5 {
    font-size: 20px;
  }

  .fs-6 {
    font-size: 16px;
  }

  /* Font Weight */
  .fw-normal {
    font-weight: normal;
  }
  .fw-light {
    font-weight: lighter;
  }
  .fw-bold {
    font-weight: bold;
  }

  /* Font Outline */
  .f-border {
    color: #ffffff;
    text-shadow: -.5px 0 var(--color-blue-darke-100), 0 .5px var(--color-blue-darke-100), .5px 0 var(--color-blue-darke-100), 0 -.5px var(--color-blue-darke-100);
  }

  /* Align */
  .text-center {
    text-align: center;
  }

  /* Bg Color */
  .bg-black-100 {
    background-color: var(--color-black-100);
  }
  .bg-yellow-100 {
    background-color: var(--color-yellow-100);
  }
  .bg-red-100 {
    background-color: var(--color-red-100);
  }
  .bg-orange-100 {
    background-color: var(--color-orange-100);
  }
  .bg-purple-100 {
    background-color: var(--color-purple-100);
  }
  .bg-orange-100 {
    background-color: var(--color-orange-100);
  }
  .bg-gray-100 {
    background-color: var(--color-gray-100);
  }
  .bg-gray-50 {
    background-color: var(--color-gray-50);
  }
  .bg-gray-30 {
    background-color: var(--color-gray-30);
  }
  .bg-gray-10 {
    background-color: var(--color-gray-10);
  }
  .bg-blue-dark-100 {
    background-color: var(--color-blue-darke-100);
  }
  .bg-blue-100 {
    background-color: var(--color-blue-100);
  }
  .bg-blue-50 {
    background-color: var(--color-blue-50);
  }
  .bg-pink-100 {
    background-color: var(--color-pink-100);
  }
  .bg-transparent {
    background-color: transparent;
  }

  /* Font Color */
  .font-black-100 {
    color: var(--color-black-100);
  }
  .font-white-100 {
    color: var(--color-white-100);
  }
  .font-yellow-100 {
    color: var(--color-yellow-100);
  }
  .font-red-100 {
    color: var(--color-red-100);
  }
  .font-orange-100 {
    color: var(--color-orange-100);
  }
  .font-orange-100 {
    color: var(--color-orange-100);
  }
  .font-gray-100 {
    color: var(--color-gray-100);
  }
  .font-gray-50 {
    color: var(--color-gray-50);
  }
  .font-gray-30 {
    color: var(--color-gray-30);
  }
  .font-gray-10 {
    color: var(--color-gray-10);
  }
  .font-blue-dark-100 {
    color: var(--color-blue-darke-100);
  }
  .font-blue-100 {
    color: var(--color-blue-100);
  }

  /* Border */
  .border-0 {
    border: none;
  }

  /* Display */
  .d-inline {
    display: inline-block;
  }
  .d-block {
    display: block;
  }
  .d-flex {
    display: flex;
  }
  .flex-column {
    flex-direction: column;
  }
  .flex-column-reverse{
    flex-direction: column-reverse;
  }
  .flex-row {
    flex-direction: row;
  }
  .flex-row-reverse {
    flex-direction: row-reverse;
  }
  .align-items-center {
    align-items: center;
  }
  .align-items-start {
    align-items: flex-start;
  }
  .align-items-end {
    align-items: flex-end;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-around {
    justify-content: space-around;
  }
  .justify-content-between {
    justify-content: space-between;
  }

  /* Margin */
  .mr-1 {
    margin-right: 1rem;
  }
  .mr-2 {
    margin-right: 2rem;
  }
  .mr-3 {
    margin-right: 3rem;
  }

  /* Z-index */
  .z-index-10 {
    z-index: 10;
  }

  /* Header */
  .wrap-header {
    text-align: center;
  }
  .wrap-header .imgs {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 200px;
    margin: 20px 0;
  }
  .wrap-header .imgs img {
    z-index: 10;
  }
  .wrap-header .imgs .sub-title {
    z-index: 1;
    margin-top: -100px;
  }
  .wrap-header .imgs .sub-title span {
    padding: 0 50px
  }
  .wrap-header > span {
    color: var(--color-gray-100);
  }

  /* Width */
  .width-fluid {
    width: 100vw;
    margin-left: calc(-50vw - -50%);
    padding: 0 60px;
  }
  .width-l {
    max-width: 1400px;
  }
  .width-m {
    max-width: 1100px;
  }

  /* Content */
  .wrap-content {
    margin: 0 auto;
    display: flex;
    margin-top: 200px;
  }
  .wrap-content h2 {
    font-size: 28px;
    margin-bottom: 30px;
  }
  .wrap-content > div {
    flex: 1;
    height: 100%;
  }
  .wrap-content p {
    word-break: keep-all;
    line-height: 25px;
    margin: 0;
    padding-right: 10%;
  }
  .wrap-content .links {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .wrap-content .links li {
    display: flex;
    align-items: center;
  }
  /* .wrap-content .links li:first-child {
    width: 100%;
    margin-bottom: 60px;
  } */
  .wrap-content .links li span {
    margin-right: 20px;
  }

  /* 소개글 */
  .wrap-content.introduce {
    align-items: flex-end;
  }

  /* 기술 소개 */
  section.tech {
    position: relative;
  }
  section.tech div > div {
    z-index: 10;
  }
  section.tech > ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  section.tech > ul li {
    position: absolute;
    transform: rotate(90deg);
  }
  section.tech > ul li:nth-child(1) {
    left: 35%;
  }
  section.tech > ul li:nth-child(2) {
    left: 5%;
    bottom: 5%;
  }
  section.tech > ul li:nth-child(3) {
    right: 7%;
    bottom: 25%;
  }
  section.tech .wrap-content {
    min-height: 720px;
  }
  section.tech .wrap-content ul {
    margin: 17px;
    padding: 0;
    line-height: 27px;
    list-style: circle;
  }
  section.tech img {
    width: 100%;
    padding: 20px;
  }

  /* 반응형 */
  @media (max-width: 820px) {
    nav, header, footer, main {
      padding: 30px;
    }
    .wrap-content {
      flex-direction: column;
      margin-top: 100px;
    }
    .wrap-content > div {
      flex: unset;
    }

    .width-fluid {
      padding: 0 30px;
    }

    /* 상단 */
    .wrap-header .imgs .sub-title {
      margin-top: 0;
    }
    .wrap-header .imgs .sub-title span {
      padding: 0;
    }

    /* 소개글 */
    .wrap-content.introduce {
      align-items: flex-start;
    }
    .wrap-content p {
      margin-right: 0;
    }
    .wrap-content .links {
      margin-top: 50px;
    }
    .wrap-content .links li {
      flex-direction: column;
      margin-bottom: 60px;
      align-items: flex-start;
    }
    .wrap-content .links li:last-child {
      margin-bottom: 0;
    }

    /* 기술 소개 */
    section.tech .wrap-content {
      align-items: flex-start;
      justify-content: center;
    }
    section.tech > ul li:nth-child(1) {
      right: 0;
      left: unset;
    }
    section.tech > ul li:nth-child(2) {
      left: -10%;
    }
    section.tech > ul li:nth-child(3) {
      right: -8%;
    }
  }

  @media (max-width: 500px) {
    footer {
      flex-direction: column;
    }
    footer > div:last-child {
      margin-top: 20px;
    }
  }
</style>
